import React from "react"

const ThomasTierney = (
  <>
    <p>
    USAF - Air Force Institute of Technology, 7th Air Force Hq. Vietnam - Tet Offensive, special weapons logistics, U-2 and SR-71 programs, Lockheed Liaison, Pentagon Research Associate - The RAND Corporation: Drones, All Volunteer Force , Kissinger defense options, major command consolidations, Office of General Officer Matters.</p>
    <p>Post-service: Nutritional supplement manufacturing, network marketing. Heath clinics now in Hanoi and Ho Chi Minh City. Emeritus Trustee University of California, Irvine, Defense Orientation Conference Association (DOCA), American Legion, Air Force Association, Society of Logistics engineers. Residence: Hailey, Idaho. 
    </p>
  </>
)

export default ThomasTierney
