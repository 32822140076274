import React from "react"

const WilliamPotter = (
  <>
    <p>
    William S. Potter (aka Potter) growing up in NW Ohio and graduating from University of
Arizona, getting and eventually taking his Engineer/Ordinance OCS Army commission
and training to Vietnam, he eventually followed a career in the food service industry,
farming and venture capital.  Throughout his entrepreneurial years he has worked and
lived in all 4 corners of the country with his wife Linda’s last move retiring them in Blaine
County, ID over 20 years ago.   Married for 50+years they have constantly involved
themselves in community “giving back” and “playing it forward” philanthropic activities. 
Most recently while conceptualizing a USS Arizona Replica Project Potter is looking
forward to being more involved USS Idaho Commissioning Committee where he can
help promote the Committee’s mission throughout the Blaine County communities.
    </p>
  </>
)

export default WilliamPotter
